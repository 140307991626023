//////////////////////////
// Entities and actions //
//////////////////////////

// BEFORE UPDATING THE VALUES BELOW: parsing policy statements is a STRICT operation --
// if the type/action isn't represented below, a policy is considered invalid.
// Removing values below could cause a failure to parse existing policies from the DB.
// PROCEED WITH CAUTION!

export enum User {
  List = "List",
  AdminList = "AdminList",
  Create = "Create",
  AdminCreate = "AdminCreate",
  ReadAttributes = "ReadAttributes",
  ReadPolicy = "ReadPolicy",
  UpdateAttributes = "UpdateAttributes",
  AdminUpdateAttributes = "AdminUpdateAttributes",
  UpdatePolicy = "UpdatePolicy",
  Delete = "Delete",
  Move = "Move",
  UpdateMfa = "UpdateMfa",
}

export enum Group {
  List = "List",
  Create = "Create",
  ReadAttributes = "ReadAttributes",
  ReadPolicy = "ReadPolicy",
  UpdateAttributes = "UpdateAttributes",
  UpdatePolicy = "UpdatePolicy",
  UpdateMembers = "UpdateMembers",
  Delete = "Delete",
}

export enum Customer {
  List = "List",
  ListUsers = "ListUsers",
  AdminListUsers = "AdminListUsers",
  Create = "Create",
  ReadAttributes = "ReadAttributes",
  ReadPolicy = "ReadPolicy",
  UpdateAttributes = "UpdateAttributes",
  UpdatePolicy = "UpdatePolicy",
  UpdateMembers = "UpdateMembers",
  Merge = "Merge",
  Delete = "Delete",
  GetNetsuiteData = "GetNetsuiteData",
  GetEmissionOverview = "GetEmissionOverview",
  GetInvoice = "GetInvoice",
  AdminGetInvoice = "AdminGetInvoice",
  GetStatistics = "GetStatistics",
  AdminGetStatistics = "AdminGetStatistics",
  ListTrailers = "ListTrailers",
  AdminListTrailers = "AdminListTrailers",
}

export enum Form {
  List = "List",
  Create = "Create",
  Read = "Read",
  Update = "Update",
  Delete = "Delete",
  Submit = "Submit",
  ReadSubmissions = "ReadSubmissions",
  DeleteSubmissions = "DeleteSubmissions",
}

export enum Fleet {
  List = "List",
  AdminList = "AdminList",
  ListTrailers = "ListTrailers",
  AdminListTrailers = "AdminListTrailers",
  Create = "Create",
  Read = "Read",
  ViewProfit = "ViewProfit",
  GetModbus = "GetModbus",
  AdminGetModbus = "AdminGetModbus",
  GetMeterData = "GetMeterData",
  AdminGetMeterData = "AdminGetMeterData",
  GetInletData = "GetInletData",
  AdminGetInletData = "AdminGetInletData",
  GetPricingConfig = "GetPricingConfig",
  UpdatePricingConfig = "UpdatePricingConfig",
  GetAllStatistics = "GetAllStatistics",
  GetRunStatistics = "GetRunStatistics",
  GetThermalEfficiency = "GetThermalEfficiency",
  AdminGetThermalEfficiency = "AdminGetThermalEfficiency",
  InitiateUsageDataUpload = "InitiateUsageDataUpload",
  UpdateAttributes = "UpdateAttributes",
  UpdateSubscription = "UpdateSubscription",
  AdminManageIotDevices = "AdminManageIotDevices",
}

export enum Trailer {
  Create = "Create",
  Update = "Update",
  List = "List",
}

export enum EntityType {
  User = "User",
  Group = "Group",
  Customer = "Customer",
  Form = "Form",
  Fleet = "Fleet",
  Trailer = "Trailer",
}

export enum EntityGroupName {
  Admins = "admins",
  Guests = "guests",
  FieldTech = "fieldtechs",
}

const TypeActionMapping = {
  User: User,
  Group: Group,
  Customer: Customer,
  Form: Form,
  Fleet: Fleet,
  Trailer: Trailer,
};

const TypeStatementSets = (function () {
  const result = {};

  for (let [type, actions] of Object.entries(TypeActionMapping)) {
    const statements = Object.values(actions).map((action) => `${type}:${action}:*`);
    result[type] = new Set(statements);
  }

  return result;
})();

/////////////////////
// Statement types //
/////////////////////

const Any = "*";
type Any = "*";

export type UserStatement = {
  type: EntityType.User;
  action: User | Any;
  scope: "*";
};

export type CustomerStatement = {
  type: EntityType.Customer;
  action: Customer | Any;
  scope: "*";
};

export type GroupStatement = {
  type: EntityType.Group;
  action: Group | Any;
  scope: "*";
};

export type FormStatement = {
  type: EntityType.Form;
  action: Form | Any;
  scope: "*";
};

export type FleetStatement = {
  type: EntityType.Fleet;
  action: Fleet | Any;
  scope: "*";
};

export type TrailerStatement = {
  type: EntityType.Trailer;
  action: Trailer | Any;
  scope: "*";
};

export type WildcardStatement = {
  type: Any;
  action: Any;
  scope: "*";
};

export type VoltagridPolicyStatement =
  | UserStatement
  | CustomerStatement
  | GroupStatement
  | FormStatement
  | FleetStatement
  | TrailerStatement
  | WildcardStatement;

///////////////////
// Parsing types //
///////////////////

export class VoltagridPolicyError extends Error {
  constructor(message, errors) {
    super(message);

    this.errors = errors;
  }

  name = "VoltagridPolicyError";
  errors: VoltagridPolicyParseError[];
}

export type VoltagridPolicyParseError = {
  index: number;
  value: string;
  error: string;
};

export type VoltagridPolicyParseResultSuccess = {
  success: true;
  policy: VoltagridPolicy;
};

export type VoltagridPolicyParseResultError = {
  success: false;
  errors: VoltagridPolicyParseError[];
};

export type VoltagridPolicyParseResult =
  | VoltagridPolicyParseResultSuccess
  | VoltagridPolicyParseResultError;

/////////////////
// Query types //
/////////////////

export enum VoltagridPolicyQueryResult {
  Allow = "allow",
  Deny = "deny",
}

export type VoltagridPolicyQueryResultAllow = {
  result: VoltagridPolicyQueryResult.Allow;
};

export type VoltagridPolicyQueryResultDeny = {
  result: VoltagridPolicyQueryResult.Deny;
  detail: string;
};

export type VoltagridPolicyEvaluationResult =
  | VoltagridPolicyQueryResultAllow
  | VoltagridPolicyQueryResultDeny;

//////////////////
// Policy class //
//////////////////

export default class VoltagridPolicy {
  constructor(statements?: VoltagridPolicyStatement[]) {
    this._rawPolicyStatements = VoltagridPolicy.expandStatements(statements ?? []);
  }

  private _rawPolicyStatements: Set<string>;

  get policyStatements(): VoltagridPolicyStatement[] {
    return Array.from(this._rawPolicyStatements).map(VoltagridPolicy.unsafeDeserializeStatement);
  }

  /**
   * Parse a serialized representation of a policy.
   *
   * @param items A list of serialized policy statements.
   * @returns A policy or a list of errors encountered during parsing.
   */
  static parse(items: string[]): VoltagridPolicyParseResult {
    if (!items) items = [];
    const result: (VoltagridPolicyStatement | VoltagridPolicyParseError)[] = items.map(
      (item, index) => {
        const pattern =
          /^(?<typeToken>[a-zA-Z*]+):(?<actionToken>[a-zA-Z*]+)(:(?<scopeToken>[a-zA-Z0-9-*]+))?$/;
        const parseResult = item.match(pattern);

        if (parseResult !== null) {
          const { typeToken, actionToken, scopeToken } = parseResult.groups! as {
            typeToken: string;
            actionToken: string;
            scopeToken: string | undefined;
          };

          let type: string;
          if (typeToken === "*") {
            type = Any;
          } else {
            if (typeToken in TypeActionMapping) {
              type = typeToken;
            } else {
              // invalid type
              return { index, value: item, error: `Invalid type "${typeToken}".` };
            }
          }

          let action: string;
          if ((actionToken === "*" && type === Any) || (actionToken === "*" && type !== Any)) {
            action = Any;
          } else if (actionToken !== "*" && type === Any) {
            // invalid wildcard
            return {
              index: index,
              value: item,
              error: `Type "*" requires action "*", but received "${typeToken}:${actionToken}".`,
            };
          } else {
            if (actionToken in TypeActionMapping[type]) {
              action = actionToken;
            } else {
              // invalid action
              return {
                index,
                value: item,
                error: `Invalid action "${actionToken}" on type "${typeToken}".`,
              };
            }
          }

          let scope: string;
          if ((scopeToken && scopeToken === "*") || scopeToken === undefined) {
            scope = "*";
          } else {
            // invalid scope
            return {
              index,
              value: item,
              error: `Action "${actionToken}" on type "${typeToken}" requires scope "*", but received "${scopeToken}".`,
            };
          }

          return { type, action, scope } as VoltagridPolicyStatement;
        } else {
          // syntax error: string doesn't match pattern
          return { index, value: item, error: "Invalid statement syntax." };
        }
      }
    );

    const errors = result.filter((item) => {
      if ("error" in item) {
        return true;
      } else {
        return false;
      }
    }) as VoltagridPolicyParseError[];

    if (errors.length > 0) {
      return {
        success: false,
        errors,
      };
    }

    return {
      success: true,
      policy: new VoltagridPolicy(result as VoltagridPolicyStatement[]),
    };
  }

  /**
   * Parse a serialized representation of a policy. If the policy is invalid, an exception is thrown.
   *
   * Use this method to quickly parse policies from trusted sources.
   *
   * @param items A list of serialized policy statements.
   */
  static unsafeParse(items: string[]): VoltagridPolicy {
    const parseResult = VoltagridPolicy.parse(items);
    if (parseResult.success) {
      return parseResult.policy;
    }

    throw new VoltagridPolicyError("Invalid policy statements.", parseResult.errors);
  }

  /**
   * Expands any wildcards policy statements into specific policy statements. Pre-existing specific
   * statements are unaffected.
   *
   * @param statements A list of policy statements, possibly including wildcard statements.
   * @returns A list of specific policy statements.
   */
  private static expandStatements(statements: VoltagridPolicyStatement[]): Set<string> {
    let result: Set<string> = new Set();

    for (let s of statements) {
      if (s.type === Any) {
        // This is a root-level wildcard that represents all types, all actions, and all scopes.
        const allStatements = Object.entries(TypeActionMapping)
          .flatMap(([type, actions]) => {
            return Object.values(actions).map((action) => ({
              type,
              action,
              scope: "*",
            }));
          })
          .map(this.serializeStatement);

        // Any other statements are irrelevant if a root-level wildcard is present, so replace
        // the existing result (if any) with all actions.
        result = new Set(allStatements);
      } else if (s.action === Any) {
        // This is a type-level wildcard that represents all actions and all scopes in a type.
        const typeStatements = Object.values(TypeActionMapping[s.type])
          .map((action) => ({
            type: s.type,
            action,
            scope: "*",
          }))
          .map(this.serializeStatement);

        for (let ts of typeStatements) {
          result.add(ts);
        }
      } else {
        // This is a plain type:action statement.
        result.add(this.serializeStatement(s));
      }
    }

    return result;
  }

  /**
   * Serializes a statement.
   *
   * The signature of this method accepts an object of the same _shape_ as a Statement because
   * there is some TypeScript weirdness in serializing dynamically created Statement objects,
   * like in expandStatements.
   *
   * @param s A policy statement object.
   * @returns A string representing the policy statement.
   */
  private static serializeStatement(s: { type: string; action: string; scope: string }): string {
    return `${s.type}:${s.action}:${s.scope}`;
  }

  /**
   * Deserialize a statement. This method doesn't attempt to parse its string argument, hence
   * "unsafe." Intended for internal use **only**.
   * @param s A string representing a policy statement.
   * @returns A policy statement.
   */
  private static unsafeDeserializeStatement(s: string): VoltagridPolicyStatement {
    const [type, action, scope] = s.split(":");
    // @ts-ignore
    return { type, action, scope };
  }

  /**
   * Serialize this policy.
   *
   * This method simplifies a policy as much as possible using wildcards (*).
   *
   * @returns A list of serialized policy statements.
   */
  serializePolicy(): string[] {
    const statements = Array.from(this._rawPolicyStatements).map(
      VoltagridPolicy.unsafeDeserializeStatement
    );
    const groups = new Map<string, Set<string>>();
    let result: Set<string> = new Set();

    // First, group all statements by type:
    for (let s of statements) {
      const rawStatement = VoltagridPolicy.serializeStatement(s);
      if (groups.has(s.type)) {
        groups.get(s.type)!.add(rawStatement);
      } else {
        groups.set(s.type, new Set([rawStatement]));
      }
    }

    // Second, simplify individual groups into wildcards if the group represents a complete type.
    // Otherwise, add the group as-is.
    for (let [group, set] of groups.entries()) {
      const completeType = TypeStatementSets[group];
      let complete = true;

      for (let statement of completeType) {
        if (!set.has(statement)) {
          complete = false;
          break;
        }
      }

      if (complete) {
        result.add(`${group}:*:*`);
      } else {
        result = new Set([...result, ...set]);
      }
    }

    // Third, simplify all groups into a root-level wildcard if all groups are complete.
    // Otherwise, return the result as-is.
    for (let entityType of Object.keys(TypeActionMapping)) {
      if (!result.has(`${entityType}:*:*`)) {
        return Array.from(result);
      }
    }

    return [`*:*:*`];
  }

  /**
   * Create a new policy from the intersection of this policy and another policy.
   *
   * @param other A policy.
   * @returns A new policy.
   */
  intersectPolicy(other: VoltagridPolicy): VoltagridPolicy {
    const otherStatements = new Set(other.policyStatements.map(VoltagridPolicy.serializeStatement));

    const policy: Set<string> = new Set();
    for (let item of this._rawPolicyStatements) {
      if (otherStatements.has(item)) {
        policy.add(item);
      }
    }

    const result = Array.from(policy).map(VoltagridPolicy.unsafeDeserializeStatement);
    return new VoltagridPolicy(result);
  }

  /**
   * Create a new policy from the union of this policy and another policy.
   *
   * @param other A policy.
   * @returns A new policy.
   */
  unionPolicy(other: VoltagridPolicy): VoltagridPolicy {
    const otherStatements = new Set(other.policyStatements.map(VoltagridPolicy.serializeStatement));

    const policy: Set<string> = new Set([...this._rawPolicyStatements, ...otherStatements]);

    const result = Array.from(policy).map(VoltagridPolicy.unsafeDeserializeStatement);
    return new VoltagridPolicy(result);
  }

  /**
   * Query this policy using the statements from another policy.
   *
   * @param other The policy to use as the query.
   * @returns A policy query result that identifies if the statements from the other policy are
   * allowed by this policy.
   */
  queryWith(other: VoltagridPolicy): VoltagridPolicyEvaluationResult {
    const policy = this._rawPolicyStatements;
    const otherStatements = new Set(other.policyStatements.map(VoltagridPolicy.serializeStatement));

    const denied: string[] = [];

    for (let item of otherStatements) {
      if (!policy.has(item)) {
        denied.push(item);
      }
    }

    if (denied.length === 0) {
      return {
        result: VoltagridPolicyQueryResult.Allow,
      };
    }

    return {
      result: VoltagridPolicyQueryResult.Deny,
      detail: `Entity is not allowed to perform actions ${denied.join(", ")}.`,
    };
  }

  /**
   * Check this policy using the statements from another policy.
   *
   * Use this method instead of `queryWith` if a detailed result is not required.
   *
   * @param other The policy to use in the check.
   * @returns True if the statements from the other policy are allowed by this policy,
   * false otherwise.
   */
  checkWith(other: VoltagridPolicy): boolean {
    const query = this.queryWith(other);

    switch (query.result) {
      case VoltagridPolicyQueryResult.Allow:
        return true;

      case VoltagridPolicyQueryResult.Deny:
        return false;
    }
  }

  /**
   * Perform a value-equality check on this policy and another policy.
   *
   * Two policies are equal if they contain the same statements.
   *
   * @param other A policy to check for equality.
   * @returns True if the policies are equal, false otherwise.
   */
  equals(other: VoltagridPolicy): boolean {
    const thisStatements = this._rawPolicyStatements;
    const otherStatements = new Set(other.policyStatements.map(VoltagridPolicy.serializeStatement));

    for (let s of otherStatements) {
      if (!thisStatements.has(s)) {
        return false;
      }
    }

    for (let s of thisStatements) {
      if (!otherStatements.has(s)) {
        return false;
      }
    }

    return true;
  }
}
