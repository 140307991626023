import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@core/services/auth/auth.guard";
import { CustomerRoles } from "@shared/enums/customer-roles.enum";
const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
    data: { roles: [] },
    canActivate: [AuthGuard],
  },
  {
    path: "guest",
    loadChildren: () => import("./guest/guest.module").then((m) => m.GuestModule),
    data: { roles: [CustomerRoles.GUEST_USER] },
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: "gas",
    loadChildren: () => import("./gas/gas.module").then((m) => m.GasModule),
    data: { roles: [CustomerRoles.GAS_ONLY_USER] },
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: "",
    loadChildren: () => import("./admin/admin.module").then((m) => m.AdminModule),
    data: { roles: [CustomerRoles.CUSTOMERADMIN, CustomerRoles.SUPERADMIN] },
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },

  { path: "**", redirectTo: "auth" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
