// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import env from "./env.json";

export const environment = {
  production: false,
  region: env.region,
  apiName: env.apiName,
  apiEndpoint: env.apiEndpoint,
  nextApiName: env.nextApiName,
  nextApiEndpoint: env.nextApiEndpoint,
  wsEndpoint: env.wsEndpoint,
  userPoolId: env.userPoolId,
  clientId: env.clientId,
  authDomain: env.authDomain,
  redirectSignIn: env.redirectSignIn,
  redirectSignOut: env.redirectSignOut,
  federatedIdName: env.federatedIdName,
  responseType: env.responseType,
  googleMeasurementId: env.googleMeasurementId,
  darkMapUrl: env.darkMapUrl,
  satMapUrl: env.satMapUrl,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import "zone.js/plugins/zone-error"; // Included with Angular CLI.
