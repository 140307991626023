import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@core/services/auth/auth.service";
import { environment } from "src/environments/environment";
import { CognitoUser } from "amazon-cognito-identity-js";

declare const gtag;

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor(private router: Router) {}

  public sendPageView(name: string) {
    gtag("event", "page_view", {
      page_title: name,
      page_location: this.router.url,
      send_to: environment.googleMeasurementId,
    });
  }

  public async setUserId(user: CognitoUser) {
    if (user) {
      user.getUserAttributes((error, attributes) => {
        const userId = attributes.find((att) => att.getName() === "sub")?.getValue();
        if (userId) {
          gtag("set", { user_id: userId });
        }
      });
    }
  }

  public removeUserId() {
    gtag("set", { user_id: undefined });
  }
}
