import { Injectable } from "@angular/core";
import { ErrorService } from "./error.service";
import { Auth } from "aws-amplify";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { HTTPRequestMethod } from "@core/utils/http-request-method.enum";
import { CacheService } from "@admin/services/cache.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  apiURL = environment.apiEndpoint;
  defaultOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private cacheService: CacheService
  ) {}

  private async getHeader(isAuthorizedRequest) {
    if (isAuthorizedRequest) {
      const header: any = {};
      header.Authorization = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
      return {
        headers: new HttpHeaders(header),
      };
    } else {
      return {
        headers: new HttpHeaders(this.defaultOptions.headers),
      };
    }
  }
  public sendRequest(
    url: string,
    requestMethod: HTTPRequestMethod,
    httpOptions,
    body?: any
  ): Promise<any> {
    let request: any;
    if (requestMethod === HTTPRequestMethod.Get) {
      request = this.http.get(url, httpOptions);
    } else if (requestMethod === HTTPRequestMethod.Post) {
      request = this.http.post(url, body, httpOptions);
    } else if (requestMethod === HTTPRequestMethod.Put) {
      request = this.http.put(url, body, httpOptions);
    } else if (requestMethod === HTTPRequestMethod.Delete) {
      request = this.http.delete(url, httpOptions);
    } else if (requestMethod === HTTPRequestMethod.Patch) {
      request = this.http.patch(url, body, httpOptions);
    }
    return new Promise((resolve, reject) => {
      request.subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async cachedGet(basePath, queryParams, validityPeriod: number = 3600000, bypassCache = false) {
    const cacheKey = queryParams ? `${basePath}: ${JSON.stringify(queryParams)}` : basePath;
    const cachedItem = this.cacheService.get(cacheKey);
    if (!bypassCache && cachedItem) {
      return cachedItem;
    }
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.get(basePath, queryParams);
        this.cacheService.add(cacheKey, response, validityPeriod);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  async get(basePath, queryParams, isAuthorizedRequest: boolean = true, includeHeaders = false) {
    let httpOptions = await this.getHeader(isAuthorizedRequest);
    if (includeHeaders) {
      httpOptions["observe"] = "response";
    }
    if (queryParams) {
      const httpParams = new HttpParams({ fromObject: queryParams });
      httpOptions["params"] = httpParams;
    }
    return this.sendRequest(`${this.apiURL}${basePath}`, HTTPRequestMethod.Get, httpOptions, null);
  }

  async delete(basePath, isAuthorizedRequest: boolean = true) {
    let httpOptions = await this.getHeader(isAuthorizedRequest);
    return this.sendRequest(
      `${this.apiURL}${basePath}`,
      HTTPRequestMethod.Delete,
      httpOptions,
      null
    );
  }

  async post(basePath, body, isAuthorizedRequest: boolean = true, includeHeaders = false) {
    let httpOptions = await this.getHeader(isAuthorizedRequest);
    if (includeHeaders) {
      httpOptions["observe"] = "response";
    }
    return this.sendRequest(`${this.apiURL}${basePath}`, HTTPRequestMethod.Post, httpOptions, body);
  }

  async put(basePath, body, isAuthorizedRequest: boolean = true) {
    let httpOptions = await this.getHeader(isAuthorizedRequest);
    return this.sendRequest(`${this.apiURL}${basePath}`, HTTPRequestMethod.Put, httpOptions, body);
  }

  async patch(basePath, body, isAuthorizedRequest: boolean = true) {
    let httpOptions = await this.getHeader(isAuthorizedRequest);
    return this.sendRequest(
      `${this.apiURL}${basePath}`,
      HTTPRequestMethod.Patch,
      httpOptions,
      body
    );
  }
}
