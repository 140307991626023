import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
@NgModule({
  declarations: [],
  providers: [CookieService],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    core: CoreModule
  ) {}
}
