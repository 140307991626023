import { Injectable } from "@angular/core";
import Auth from "@aws-amplify/auth";
import Api from "@aws-amplify/api";
import { Hub } from "@aws-amplify/core";
import { Subject, Observable, of } from "rxjs";
import { take } from "rxjs/operators";
import { CognitoUser } from "amazon-cognito-identity-js";
import { environment } from "src/environments/environment";
import { MfaTypeEnum } from "../../utils/mfa-type.enum";
import { WebsocketService } from "@admin/services/ws.service";
import { CustomerRoles } from "@shared/enums/customer-roles.enum";
import { ApiService } from "../api.service";
import { ErrorService } from "../error.service";
import { Capacitor } from "@capacitor/core";
import { AnalyticsService } from "@admin/services/analytics.service";
import { CacheService } from "@admin/services/cache.service";
import { CustomerFeatures } from "@shared/enums/customer-features.enum";
import VoltagridPolicy, { VoltagridPolicyStatement } from "@voltagrid/common/lib/VoltagridPolicy";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public loggedIn: boolean;
  public activeUser: any;
  private _authState: Subject<CognitoUser | any> = new Subject<CognitoUser | any>();
  private _userPolicy: VoltagridPolicy;
  authState: Observable<CognitoUser | any> = this._authState.asObservable();

  constructor(
    private websocketService: WebsocketService,
    private apiService: ApiService,
    private errorService: ErrorService,
    private analyticsService: AnalyticsService,
    private cacheService: CacheService
  ) {
    const websocketConnect = () => {
      this.websocketService
        .connect(async () => {
          const session = await Auth.currentSession();
          return session.getIdToken().getJwtToken();
        })
        .then(() => {
          console.log("Connected to WebSocket");
        });
    };

    this.isAuthenticated().then((isAuth) => {
      if (isAuth) {
        websocketConnect();
      }
    });

    Hub.listen("auth", async (data) => {
      const { channel, payload } = data;
      if (channel === "auth") {
        console.log("auth");
        if (await this.isAuthenticated()) {
          this.userPolicy = this.parseUserPolicy(this.activeUser);
        }
        this._authState.next(payload.event);
      }
    });

    this.authState.subscribe((payload) => {
      console.log("state", payload);
      if (payload === "signIn") {
        websocketConnect();
      }
    });
  }

  public get userPolicy() {
    if (!this._userPolicy && this.activeUser) {
      this._userPolicy = this.parseUserPolicy(this.activeUser);
    }
    return this._userPolicy;
  }

  public set userPolicy(userPolicy: VoltagridPolicy) {
    this._userPolicy = userPolicy;
  }

  public async logout() {
    return new Promise(async (resolve, reject) => {
      await Auth.signOut();
      await this.websocketService.close();
      this.analyticsService.removeUserId();
      this.cacheService.clear();
      this.authState = of(null);
      this.loggedIn = false;
      resolve(true);
    });
  }
  checkPath(email: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const result = await Api.get(environment.apiName, `/useIdp/${email}`, null);
      this.authState = of(email);
      if (!Capacitor.isNativePlatform() && result?.isVoltagridEmail) {
        await Auth.federatedSignIn({
          customProvider: environment.federatedIdName,
        });
      }
      return resolve(result);
    });
  }

  signInWithEmailAndPassword(email: string, password: string): Promise<CognitoUser | any> {
    return new Promise((resolve, reject) => {
      Auth.signIn(email, password)
        .then((user: CognitoUser | any) => {
          this.authState = of(user);
          resolve(user);
        })
        .catch((error: any) => reject(error));
    });
  }

  signIn(password: string): Promise<CognitoUser | any> {
    return new Promise((resolve, reject) => {
      this.authState.pipe(take(1)).subscribe((email: string) => {
        Auth.signIn(email, password)
          .then((user: CognitoUser | any) => {
            this.authState = of(user);
            this.analyticsService.setUserId(user);
            resolve(user);
          })
          .catch((error: any) => reject(error));
      });
    });
  }
  /**
   * Grabbing a user's entity resolved policy if exist.
   * Parsing a serialized representation of a policy.
   * If it doesn't exist, return an empty VoltagridPolicy object.
   *
   * @param cognitoUser A user
   * @returns A VoltagridPolicy object
   */
  parseUserPolicy = (cognitoUser: CognitoUser | any): VoltagridPolicy => {
    try {
      const entityResolvedPolicy: string[] = cognitoUser.signInUserSession
        .getIdToken()
        .payload.entity_resolved_policy.split(",");
      const parse = VoltagridPolicy.parse(entityResolvedPolicy);
      if (parse.success) {
        return parse.policy;
        // Had to specifically check for false to use type VoltagridPolicyParseResultError
      } else if (parse.success === false) {
        console.log(`Auth Service: parseUserPolicy: Errors: ${parse.errors}`);
        return new VoltagridPolicy();
      }
    } catch (error: any) {
      console.log(`Auth Service: parseUserPolicy: Errors: ${error}`);
      return new VoltagridPolicy();
    }
  };

  checkPassword(password: string) {
    return new Promise((resolve, reject) => {
      Auth.signIn(this.authState["attributes"]["email"], password)
        .then((user: CognitoUser | any) => {
          resolve(true);
        })
        .catch((err) => {
          reject(false);
        });
    });
  }

  changePassword(newPassword: string): Promise<CognitoUser | any> {
    return new Promise((resolve, reject) => {
      this.authState.pipe(take(1)).subscribe((user: CognitoUser) => {
        Auth.completeNewPassword(user, newPassword, [])
          .then((res: CognitoUser | any) => {
            this.authState = of(res);
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    });
  }

  verifyMfaCode(code: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.authState.pipe(take(1)).subscribe(async (user: CognitoUser) => {
        Auth.confirmSignIn(user, code, user["challengeName"])
          .then((res) => {
            this.authState = of(res);
            resolve(res);
          })
          .catch((err) => {
            console.log("error confirming signin", err);
            reject(err);
          });
      });
    });
  }

  forgotPassword(email: string): Promise<string> {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(email)
        .then((data) => {
          this.authState = of(email);
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }
  changeCurrentUserPassword(oldPassword, newPassword): Promise<CognitoUser | any> {
    return new Promise((resolve, reject) => {
      Auth.changePassword(this.activeUser, oldPassword, newPassword).then(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  resetPassword(code: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.authState.pipe(take(1)).subscribe(async (user: CognitoUser | any) => {
        Auth.forgotPasswordSubmit(user, code, password)
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    });
  }

  public async isAuthenticated() {
    try {
      this.activeUser = await Auth.currentAuthenticatedUser();
      return true;
    } catch (error) {
      return false;
    }
  }

  // Checking for User Policy Statement for permissions
  isUserPolicyStatement(vgPolicyStatement: VoltagridPolicyStatement[]): boolean {
    return this.userPolicy?.checkWith(new VoltagridPolicy(vgPolicyStatement));
  }

  isSuperAdmin() {
    return (
      this.activeUser &&
      this.activeUser.attributes?.["custom:roles"]?.startsWith(CustomerRoles.SUPERADMIN)
    );
  }

  isCustomerAdmin() {
    return (
      this.activeUser &&
      this.activeUser.attributes?.["custom:roles"]?.startsWith(CustomerRoles.CUSTOMERADMIN)
    );
  }

  isGuestUser() {
    return (
      this.activeUser &&
      this.activeUser.attributes?.["custom:roles"]?.startsWith(CustomerRoles.GUEST_USER)
    );
  }

  isGasOnlyUser() {
    return (
      this.activeUser &&
      this.activeUser.attributes?.["custom:roles"]?.startsWith(CustomerRoles.GAS_ONLY_USER)
    );
  }

  canViewProfit() {
    return this.hasFeature(CustomerFeatures.ViewProfit);
  }

  getCustomerId() {
    return this.activeUser && this.activeUser.attributes?.["custom:tenant"];
  }

  setupTOTP(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      Auth.setupTOTP(this.activeUser).then((code) => {
        const qrCode =
          "otpauth://totp/AWSCognito:" +
          this.activeUser["username"] +
          "?secret=" +
          code +
          "&issuer=Voltagrid";
        resolve({
          qrCode,
          sharedCode: code,
        });
      });
    });
  }

  async isMFAEnabled() {
    return (await Auth.getPreferredMFA(this.activeUser)) !== MfaTypeEnum.NOMFA;
  }

  async disableMFA() {
    await Auth.setPreferredMFA(this.activeUser, MfaTypeEnum.NOMFA);
  }

  async enableMFA(mfaType: MfaTypeEnum.SMS | MfaTypeEnum.TOTP) {
    await Auth.setPreferredMFA(this.activeUser, mfaType);
  }

  confirmTOTPChallenge(challengeAnswer: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      // Use the generated one-time password to verify the setup
      Auth.verifyTotpToken(this.activeUser, challengeAnswer)
        .then(() => {
          return Auth.setPreferredMFA(this.activeUser, MfaTypeEnum.TOTP);
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //["TOTP","SMS","NOMFA"]
  setPreferredMFA(preferedMFA: MfaTypeEnum): Promise<any> {
    // Select TOTP as preferred
    return new Promise(async (resolve, reject) => {
      Auth.setPreferredMFA(this.authState, preferedMFA)
        .then((data) => {
          console.log(data);
          resolve(true);
          // ...
        })
        .catch((e) => {
          reject(e.message);
        });
    });
  }
  getPreferredMFA() {
    // Will retrieve the current mfa type from cache
    return Auth.getPreferredMFA(this.authState, {
      // Optional, by default is false.
      // If set to true, it will get the MFA type from server side instead of from local cache.
      bypassCache: false,
    }).then((data) => {
      console.log("Current preferred MFA type is: " + data);
      return data;
    });
  }

  getUserChallengeName(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.authState.pipe(take(1)).subscribe(async (user: CognitoUser) => {
        resolve(user["challengeName"]);
      });
      reject();
    });
  }

  private hasFeature(feature: CustomerFeatures) {
    const featureFlags = this.getFeatureFlags();
    if (featureFlags) {
      return featureFlags.includes(feature);
    }
    return false;
  }

  private getFeatureFlags(): string[] | undefined {
    if (this.activeUser) {
      const roles: string = this.activeUser.attributes?.["custom:roles"];
      if (roles) {
        return roles.substring(roles.indexOf(":") + 1).split(",");
      }
    }
    return undefined;
  }
}
