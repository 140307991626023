import { GlobalVars } from "@admin/constants/global-vars";
import { AnalyticsService } from "@admin/services/analytics.service";
import { isMobile } from "@admin/utils/device-type.util";
import { Component } from "@angular/core";
import { AuthService } from "@core/services/auth/auth.service";
import { environment } from "src/environments/environment";

declare const gtag;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(private authService: AuthService, private analyticsService: AnalyticsService) {
    gtag("config", environment.googleMeasurementId);
  }

  async ngOnInit() {
    GlobalVars.displayMobile = isMobile();
    if (await this.authService.isAuthenticated()) {
      this.analyticsService.setUserId(this.authService.activeUser);
      // Reccomendation is to always register for push notifications on app launch since
      // the token can change
      // await this.pushNotificationService.registerForPushNotifications();
      // await this.pushNotificationService.checkIfLaunchedFromPushNotification();
    }
  }
}
