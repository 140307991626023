import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  constructor() {}

  /**
   * Handler for catchError operator during HTTP requests.
   */
  public handleCloudError(error: any): Error {
    console.log("error:", error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else if (error!.response!.status) {
      // The backend returned an unsuccessful response code.
      console.error(`Cloud code #${error.response.status} - ${error.statusText}: `, error.message);
      return new Error(error.message);
    } else {
      return new Error("Communication problem - please check your Internet connection.");
    }
  }
}
