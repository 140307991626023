import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { from, interval, Subscription } from "rxjs";
import { mergeMap } from "rxjs/operators";

export type fetchAccessTokenType = () => Promise<string>;

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  pingInterval = 5 * 60 * 1000;
  webApi = environment.wsEndpoint;
  webSocket?: WebSocket;
  pingSubscription?: Subscription;

  fetchAccessToken: fetchAccessTokenType;

  constructor() {
    console.log("Init WebSocket");
  }

  async connect(fetchCallback: fetchAccessTokenType) {
    if (this.webSocket) {
      return;
    }

    this.fetchAccessToken = fetchCallback;
    this.webSocket = new WebSocket(this.webApi);
    this.webSocket.onmessage = (e) => this.onMessage(e);
    this.webSocket.onopen = () => this.auth();
    this.webSocket.onclose = () => {
      console.log("close");
      this.close();
      this.connect(fetchCallback);
    };
  }

  async auth() {
    if (!this.webSocket) {
      return;
    }

    const token = await this.fetchAccessToken();
    this.webSocket.send(
      JSON.stringify({
        action: "auth",
        authorization: token,
      })
    );
  }

  private async onMessage(event) {
    const data = JSON.parse(event.data);
    switch (data.action) {
      case "auth": {
        if (!this.pingSubscription) {
          this.pingSubscription = interval(this.pingInterval)
            .pipe(mergeMap((v, i) => from(this.ping())))
            .subscribe();
        }
        break;
      }
      default:
        break;
    }
  }

  async ping() {
    if (!this.webSocket) {
      return;
    }
    console.log("ping");
    const token = await this.fetchAccessToken();
    this.webSocket.send(
      JSON.stringify({
        action: "ping",
        authorization: token,
      })
    );
  }

  close() {
    if (this.webSocket) {
      this.webSocket.onclose = null;
      this.webSocket.close();
      this.webSocket = null;
    }

    if (this.pingSubscription) {
      this.pingSubscription.unsubscribe();
      this.pingSubscription = null;
    }

    this.fetchAccessToken = null;
  }
}
