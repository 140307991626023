import { Injectable } from "@angular/core";
import {
  Router,
  UrlTree,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from "@angular/router";
import { CustomerRoles } from "@shared/enums/customer-roles.enum";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanLoad, CanActivate {
  constructor(private _router: Router, public authService: AuthService) {}

  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean | UrlTree> {
    return this.processAuth(route);
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return this.processAuth(route);
  }

  async processAuth(route: Route | ActivatedRouteSnapshot) {
    const roles = route.data.roles || [];
    if (await this.authService.isAuthenticated()) {
      if (!roles || roles.length === 0) {
        return true;
      }
      if (
        (roles.includes(CustomerRoles.GUEST_USER) && this.authService.isGuestUser()) ||
        (roles.includes(CustomerRoles.GAS_ONLY_USER) && this.authService.isGasOnlyUser()) ||
        (roles.includes(CustomerRoles.SUPERADMIN) && this.authService.isSuperAdmin()) ||
        (roles.includes(CustomerRoles.CUSTOMERADMIN) && this.authService.isCustomerAdmin())
      ) {
        return true;
      } else {
        if (this.authService.isGuestUser()) {
          this._router.navigate(["/guest"]);
        } else if (this.authService.isGasOnlyUser()) {
          this._router.navigate(["/gas"]);
        } else {
          this._router.navigate(["/dashboard"]);
        }
        return false;
      }
    } else {
      if (roles.length !== 0) {
        this._router.navigate(["/auth"]);
        return false;
      } else {
        return true;
      }
    }
  }
}
