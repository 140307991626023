import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import Amplify from "@aws-amplify/core";

if (environment.production) {
  enableProdMode();
}

Amplify.configure({
  API: {
    endpoints: [
      {
        name: environment.apiName,
        endpoint: environment.apiEndpoint,
      },
    ],
  },
  Auth: {
    region: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.clientId,
    oauth: {
      domain: environment.authDomain,
      scope: ["email", "openid", "profile"],
      redirectSignIn: environment.redirectSignIn,
      redirectSignOut: environment.redirectSignOut,
      responseType: environment.responseType,
    },
  },
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
