import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CacheService {
  readonly maxItems = 100;

  private cache: { [key: string]: { item: any; expiryDate: number } } = {};

  constructor() {}

  public add(key: string, item: any, validityPeriod: number = 3600000) {
    this.cache[key] = {
      item,
      expiryDate: new Date().getTime() + validityPeriod,
    };
    if (Object.keys(this.cache).length > this.maxItems) {
      this.trimCache();
    }
  }

  public get(key: string) {
    const cachedItem = this.cache[key];
    if (cachedItem && new Date().getTime() < cachedItem.expiryDate) {
      return cachedItem.item;
    }
    return undefined;
  }

  public clear() {
    this.cache = {};
  }

  private trimCache() {
    this.removeExpiredItems();
    if (Object.keys(this.cache).length > this.maxItems) {
      this.removeOldestItems();
    }
  }

  private removeExpiredItems() {
    const now = new Date().getTime();
    for (const [key, value] of Object.entries(this.cache)) {
      if (now >= value.expiryDate) {
        delete this.cache[key];
      }
    }
  }

  private removeOldestItems() {
    const removeCount = Math.ceil(Object.keys(this.cache).length * 0.2);
    const sorted = Object.entries(this.cache).sort((a, b) => {
      return a[1].expiryDate - b[1].expiryDate;
    });
    const oldestEntries = sorted.slice(0, removeCount);
    oldestEntries.forEach((entry) => {
      delete this.cache[entry[0]];
    });
  }
}
